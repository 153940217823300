import React from "react"
import styled from "styled-components"

const LabelText = styled.span`
  background-color: #F7C8C8;
  margin: 0 0.2rem;
  padding: 0.2rem 0.3rem;
  border-radius: 5px;
  color: black;
  font-family: 'Montserrat', sans-serif;
  font-size: 0.55rem;
  font-weight: 400;
  text-transform: uppercase;
`;

type Props = {
  children: React.ReactNode;
}

const Label = ({ children }: Props) => (
    <LabelText>{children}</LabelText>
)

export default Label
