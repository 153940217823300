import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled, { css } from "styled-components"
import Layout from "../components/layout"
import Title from "../components/elements/title"
import Text from "../components/elements/text"
import CustomLink from "../components/elements/link"
import Emoji from "../components/elements/emoji"
import CheatsheetThumbnail from '../components/cheatsheetThumbnail'
import List from "../components/list"
import SEO from "../components/seo"

const CheatsheetsContainer = styled.div(
  () => css`
    padding: 1rem 0 2rem 0;
  `
)

const CheatsheetsPage = () => {
  const data = useStaticQuery(graphql`
    query cheatsheetsQuery {
      allCheatsheetsJson {
        edges {
          node {
            title
            titlecz
            image
            lang
            type
            source {
              name
              link
            }
            format
            url
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Cheatsheets" />
      <Title size="h2">Taháky</Title>
      <CheatsheetsContainer>
      {data.allCheatsheetsJson.edges && data.allCheatsheetsJson.edges.map(edge => (
        <CheatsheetThumbnail key={edge.node.title} image={edge.node.image} title={edge.node.title} titlecz={edge.node.titlecz} lang={edge.node.lang} link={edge.node.url} type={edge.node.type} source={edge.node.source} format={edge.node.format} />
      ))}
      </CheatsheetsContainer>
    </Layout>
  )
}

export default CheatsheetsPage
