import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMediumM, faGithub, faMarkdown } from '@fortawesome/free-brands-svg-icons'
import { faFilePdf  } from '@fortawesome/free-solid-svg-icons'
import { config } from "@fortawesome/fontawesome-svg-core"
import "@fortawesome/fontawesome-svg-core/styles.css"
import styled, { css } from "styled-components"
import CustomLink from "./elements/link"
import Emoji from './elements/emoji'
import Label from "./label"

config.autoAddCss = false

const iconColors = {
  Markdown: '#2a84d2',
  PDF: '#960002',
  Github: '#000',
  Medium: '#000',
}

const filesTypes = {
  Markdown: faMarkdown,
  PDF: faFilePdf,
}

const sourceTypes = {
  Github: faGithub,
  Medium: faMediumM,
}

const Wrapper = styled.div(
  css`
    padding: 0.5rem;
  `
);

const Container = styled.div(
  css`
    height: 100%;
    display: flex;
    flex-direction: column;
    border: 2px solid #b3dec1;
    overflow: hidden;

    @media screen and (min-width: 540px) {
      flex-direction: row;
    }
  `
);

const ThumbnailImageWrapper = styled.div(
  css`
    height: 15rem;
    flex: 2;
    overflow: hidden;

    @media screen and (min-width: 540px) {
      height: auto;
    }
  `
);

const ThumbnailImage = styled.div<{bg: string}>`
  width: 100%;
  height: 100%;
  background: url(${props => props.bg});
  background-size: cover;
  background-position: center;
  text-decoration: none;
  filter: grayscale(40%);

  &:hover {
    filter: grayscale(0);
    transition: transform .4s, filter 1s ease-in-out;
    transform: rotate(-9deg) scale(1.3);
  }

  @media screen and (min-width: 540px) {
    &:hover {
      transform: rotate(-10deg) scale(1.3);
    }
  }
`

const ThumbnailContainer = styled.div(
  css`
    flex: 3;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0.7rem;
  `
);

const ThumbnailTitle = styled.h3(
  css`
    color: #000;
    font-family: "Montserrat", sans-serif;
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 1.7;
    word-break: break-word;

    @media screen and (min-width: 450px) {
      font-size: 1rem;
    }

    @media screen and (min-width: 500px) {
      font-size: 0.95rem;
      margin: 0 0 1rem 0;
    }
  `
);

const ThumbnailSubtitle = styled.h4(
  css`
    display: block;
    color: #000;
    font-family: "Montserrat", sans-serif;
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 1.5;
    word-break: break-word;

    @media screen and (min-width: 450px) {
      font-size: 0.95rem;
    }

    @media screen and (min-width: 500px) {
      font-size: 0.8rem;
      margin: 0.25rem 0;
    }
  `
);


const LinkWrapper = styled.div(
  css`
    display: flex;
    justify-content: flex-end;
  `
);

const Icon = styled(FontAwesomeIcon)(
  ({ iconColor }) => css`
    color: ${iconColor};
    font-size: 1.75rem;

    @media screen and (min-width: 600px) {
      font-size: 1.2rem;
    }
  `
);

type SourceProps = {
  name: string,
  link: string
}

type Props = {
  image: string,
  title: string,
  titlecz: string,
  source: Array<SourceProps>,
  type: Array<string>,
  lang: string,
  format: string,
  link: string,
}

const CheatsheetThumbnail = ({ image, title, titlecz, source, type, lang, format, link }: Props) => (
  <Wrapper>
    <Container>
      <ThumbnailImageWrapper>
        <ThumbnailImage bg={image} />
      </ThumbnailImageWrapper>
      <ThumbnailContainer>
      <ThumbnailTitle>{titlecz} {lang === "CZ" ? (<Emoji label="flag-cz" symbol="🇨🇿" />) : (<Emoji label="flag-en" symbol="🇬🇧" />)} {type && type.map(item => <Label key={item}>{item}</Label>)}</ThumbnailTitle>
        {/* <ThumbnailSubtitle>{`Czech title: ${titlecz}`}</ThumbnailSubtitle> */}
        <ThumbnailSubtitle>Formát: <Icon iconColor={iconColors[format]} icon={filesTypes[format]} /></ThumbnailSubtitle>
        <ThumbnailSubtitle>Dostupné na: {source && source.map(({name, link}) => <CustomLink href={link} targetBlank><Icon key={name} iconColor={iconColors[name]} icon={sourceTypes[name]} /></CustomLink>)}</ThumbnailSubtitle>
        <LinkWrapper>
          <CustomLink href={link} targetBlank navLink>Stáhnout</CustomLink>
        </LinkWrapper>
      </ThumbnailContainer>
    </Container>
  </Wrapper>
)

export default CheatsheetThumbnail
